import { extendTheme } from '@chakra-ui/react';

const theme = {
  breakpoints: {
    sm: '600px',
    md: '830px',
    lg: '1200px',
    xl: '1440px',
    '2xl': '1500px',
  },
  fonts: {
    heading: '"PT Sans Narrow", sans-serif',
    body: '"PT Sans", sans-serif',
    // '"PT Serif", serif',
  },
  fontSizes: {
    xs: '10px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '32px',
    '5xl': '36px',
    '6xl': '40px',
    '7xl': '45px',
    '8xl': '60px',
    '9xl': '72px',
  },
  fontWeights: {
    normal: 400,
    bold: 700,
  },
  lineHeights: {
    normal: 'normal',
    none: 1,
    shorter: 1.2,
    short: 1.3,
    base: 1.4,
    tall: 1.6,
    taller: '2',
  },
  letterSpacings: {
    tight: '0em',
    normal: '0.04em',
    wide: '0.06em',
  },
  radii: {
    none: '0',
    sm: '2px',
    base: '4px',
    md: '6px',
    lg: '8px',
    xl: '10px',
    '2xl': '14px',
    '3xl': '18px',
    full: '9999px',
  },
  colors: {
    background: {
      light: '#EEF0F2',
      dark: '#263C58',
    },
    brand: {
      primary: '#263C58',
      50: '#6f93c1',
      100: '#4c79b1',
      200: '#456d9f',
      300: '#3d608d',
      400: '#35547c',
      500: '#263C58',
      600: '#172434',
      700: '#0f1823',
      800: '#070b11',
      900: '#000000',
    },
    brandBlue: {
      primary: '#4C78B0',
      50: '#c7d5e7',
      100: '#a4bbd8',
      200: '#92add0',
      300: '#80a0c8',
      400: '#6e93c1',
      500: '#4C78B0',
      600: '#3d608c',
      700: '#35547b',
      800: '#2d4769',
      900: '#1e2f45',
    },
    brandAlpha: {
      50: 'rgba(38, 60, 88, 0.04)',
      100: 'rgba(38, 60, 88, 0.06)',
      200: 'rgba(38, 60, 88, 0.10)', // 08
      300: 'rgba(38, 60, 88, 0.17)', // 16
      400: 'rgba(38, 60, 88, 0.24)',
      500: 'rgba(38, 60, 88, 0.36)',
      600: 'rgba(38, 60, 88, 0.48)',
      700: 'rgba(38, 60, 88, 0.65)', // 64
      800: 'rgba(38, 60, 88, 0.8)',
      900: 'rgba(38, 60, 88, 0.92)',
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontFamily: 'body',
        letterSpacing: '.08em',
        fontWeight: 'normal',
        borderRadius: '2px',
        borderWidth: '2px',
        borderColor: 'transparent',
      },
      variants: {
        ghost: {
          borderWidth: '2px',
          borderColor: 'brandBlue.500',
          color: 'white',
          '&:hover': {
            background: 'whiteAlpha.200',
          },
          '&:active': {
            background: 'whiteAlpha.400',
          },
        },
      },
      sizes: {
        lg: {
          h: '48px',
          fontSize: 'md',
          px: '24px',
        },
        md: {
          h: '42px',
          fontSize: 'md',
          px: '28px',
        },
        sm: {
          h: '32px',
          fontSize: 'sm',
          px: '12px',
        },
        sx: {
          h: '24px',
          fontSize: '12px',
          px: '10px',
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            width: '100%',
            border: '1px solid',
            borderColor: ' #d1d1d1',
            borderRadius: '2px',
            bg: 'white',
          },
        },
      },
    },
    Textarea: {
      variants: {
        outline: {
          width: '100%',
          border: '1px solid',
          borderColor: ' #d1d1d1',
          borderRadius: '2px',
          bg: 'white',
        },
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            width: '100%',
            border: '1px solid',
            borderColor: ' #d1d1d1',
            borderRadius: '2px',
            bg: 'white',
          },
        },
      },
    },
  },
};

export default extendTheme(theme);
