exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-enter-to-win-tsx": () => import("./../../../src/pages/enter-to-win.tsx" /* webpackChunkName: "component---src-pages-enter-to-win-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-magazine-advertise-with-us-tsx": () => import("./../../../src/pages/magazine/advertise-with-us.tsx" /* webpackChunkName: "component---src-pages-magazine-advertise-with-us-tsx" */),
  "component---src-pages-magazine-index-tsx": () => import("./../../../src/pages/magazine/index.tsx" /* webpackChunkName: "component---src-pages-magazine-index-tsx" */),
  "component---src-pages-magazine-subscription-tsx": () => import("./../../../src/pages/magazine/subscription.tsx" /* webpackChunkName: "component---src-pages-magazine-subscription-tsx" */),
  "component---src-pages-privacy-and-conditions-tsx": () => import("./../../../src/pages/privacy-and-conditions.tsx" /* webpackChunkName: "component---src-pages-privacy-and-conditions-tsx" */)
}

